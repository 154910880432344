import React from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import { Input } from '../../../../../../_metronic/_partials/controls'
export function TYTestimonialAdd({ updateSettings, customer, actionsLoading }) {
  const saveCustomer = async (videoUrl) => {
    let requestArray = { thankYouPageVideoUrl: videoUrl?.videoUrl }
    updateSettings(requestArray)
  }
  const initialValues = {
    videoUrl: customer?.thankYouPageVideo?.userValue || '',
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        saveCustomer(values)
      }}
    >
      {({ handleSubmit, touched, errors }) => (
        <>
          <Modal.Body className="overlay">
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-12 mt-3">
                  <Field
                    name={`videoUrl`}
                    component={Input}
                    placeholder="Youtube Link"
                    label={`Youtube Link`}
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              onClick={() => (!actionsLoading ? handleSubmit() : null)}
              className="btn btn-primary btn-elevate"
            >
              <span className={`${actionsLoading ? 'pr-3' : ''}`}>Save</span>
              {actionsLoading && (
                <span className="spinner-border text-light"></span>
              )}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  )
}

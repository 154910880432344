import { Api, Urls } from '../../../../../redux/axiosSetup'

// CREATE =>  POST: add a new customer to the server
export function updateSettings(customer) {
  return Api.post(Urls.settings, customer)
}

export function fetchSettings() {
  return Api.get(`${Urls.settings}`)
}

// Reset cache
export function resetCache() {
  return Api.get(Urls.resetCache)
}

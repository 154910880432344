import MockAdapter from 'axios-mock-adapter'
//import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockCourses from '../../app/modules/Course/__mocks__/mockCategory'
// import mockStudents from '../../app/modules/Students/_mocks_/mockStudents';

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 })

  mockCourses(mock)
  // mockStudents(mock);

  return mock
}

import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Input } from '../../../../_metronic/_partials/controls'
import { injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { resetPassword } from '../_redux/authCrud'

const ResetPassword = (props) => {
  const { intl } = props
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState()

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }
  const verificationCode = window.location.href.split('verificationcode=')[1]
  const getInputClasses = (meta, fieldname) => {
    let result = 'form-control form-control-solid h-auto py-5 px-6 '
    if (meta.touched && meta.error) {
      result += ' is-invalid'
    }

    if (meta.touched && !meta.error) {
      result += ' is-valid'
    }

    return result
  }
  return (
    <div className="login-form login_box_shadow">
      <div className="text-center mb-15 mb-lg-20">
        <h3 className="font-size-h1">Forgotten Password ?</h3>
      </div>

      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validate={(values) => {
          const errors = {}

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD',
            })
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD',
            })
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword =
              "Password and Confirm Password didn't match."
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          enableLoading()
          resetPassword(values.password, verificationCode)
            .then(() => {
              disableLoading()
              window.location.href = '/auth/login'
            })
            .catch((e) => {
              let message = e.response.data.metadata.message
              setSubmitting(false)
              setStatus(message)
              disableLoading()
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            {status ? (
              <div
                role="alert"
                className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
              >
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className="form-group">
              <Field
                type="password"
                name="password"
                component={Input}
                placeholder="Password"
                label="Password"
              >
                {({ field, form, meta }) => (
                  <div>
                    <input
                      type="password"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder="New Password"
                    />
                    {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field
                type="password"
                name="confirmPassword"
                component={Input}
                placeholder="Confirm Password"
                label="Confirm Password"
              >
                {({ field, form, meta }) => (
                  <div>
                    <input
                      type="password"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder="Confirm Password"
                    />
                    {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isSubmitting}
              >
                <span className={`${loading ? 'pr-3' : ''}`}>Submit</span>
                {loading && <span className="spinner-border text-light"></span>}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(ResetPassword))

/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from 'react'
import objectPath from 'object-path'
import ApexCharts from 'apexcharts'
import { useHtmlClassService } from '../../../layout'
import { KTUtil } from '../../../_assets/js/components/util'

export function DonutMixedWidget({ className, item }) {
  const uiService = useHtmlClassService()

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        'js.colors.gray.gray100',
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        'js.colors.gray.gray700',
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        'js.colors.theme.base.success',
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        'js.colors.theme.light.success',
      ),
      fontFamily: objectPath.get(uiService.config, 'js.fontFamily'),
    }
  }, [uiService])

  useEffect(() => {
    const element = document.getElementById(item.id)
    if (!element) {
      return
    }

    const height = parseInt(KTUtil.css(element, 'height'))
    const options = getChartOptions(layoutProps, height, item)

    const chart = new ApexCharts(element, options)
    chart.render()
    return function cleanUp() {
      chart.destroy()
    }
  }, [item, layoutProps])
  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {item.title}
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {item.discription}
          </span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id={item.id} style={{ height: '200px' }}></div>
        </div>
      </div>
    </div>
  )
}

function getChartOptions(layoutProps, height, item) {
  const options = {
    legend: {
      show: true,
      position: 'bottom',
    },
    series: item.series,
    labels: item.labels,
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      height: height,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: '65%',
        donut: {
          labels: {
            show: true,
            showOn: 'always',
            name: {
              show: false,
              fontWeight: '700',
            },
            total: {
              showAlways: true,
              fontSize: '20px',
              fontWeight: '900',
              show: true,
              formatter: function () {
                return item.count
              },
            },
          },
        },
      },
    },
    colors: item && item.colors ? item.colors : undefined,
    stroke: {
      show: false,
    },
  }
  return options
}

import React from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import { Input } from '../../../../../../_metronic/_partials/controls'
export function VideoTestimonialAdd({
  updateSettings,
  customer,
  actionsLoading,
}) {
  const saveCustomer = async (testimonial) => {
    let videoUrlsArray = []
    await Object.keys(testimonial).map((key, index) => {
      if (testimonial[key]) {
        videoUrlsArray.push({
          videoUrlID: index + 1,
          videoUrl: testimonial[key],
        })
      }
      return null
    })
    let requestArray = { youTubeVideoUrl: videoUrlsArray }
    updateSettings(requestArray)
  }
  const initialValues = {}
  for (let i = 0; i < 9; i++) {
    initialValues[`youtube_testimonial_${i + 1}`] =
      (customer?.youTubeVideoUrl[i] && customer?.youTubeVideoUrl[i].videoUrl) ||
      ''
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        saveCustomer(values)
      }}
    >
      {({ handleSubmit, touched, errors }) => (
        <>
          <Modal.Body className="overlay">
            <Form className="form form-label-right">
              <div className="form-group row">
                {Object.keys(initialValues).map((data, i) => {
                  return (
                    <div className="col-lg-4 mt-3">
                      <Field
                        name={`${data}`}
                        component={Input}
                        placeholder="Youtube Link"
                        label={`Youtube Link ${i + 1}`}
                      />
                    </div>
                  )
                })}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              onClick={() => (!actionsLoading ? handleSubmit() : null)}
              className="btn btn-primary btn-elevate"
            >
              <span className={`${actionsLoading ? 'pr-3' : ''}`}>Save</span>
              {actionsLoading && (
                <span className="spinner-border text-light"></span>
              )}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  )
}

import * as requestFromServer from './settingsCrud'
import { settingsSlice, callTypes } from './settingsSlice'

const { actions } = settingsSlice

export const updateSettings = (customerForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .updateSettings(customerForCreation)
    .then((response) => {
      dispatch(actions.customerCreated({ customerForCreation }))
      return response
    })
    .catch((errorRes) => {
      let error = errorRes.response
      dispatch(actions.catchError({ error, callType: callTypes.action }))
      return error
    })
}

export const resetCache = () => (dispatch) => {
  return requestFromServer
    .resetCache()
    .then((response) => {
      return response
    })
    .catch((error) => {
      error.clientMessage = 'Failed to reset cache.'
      dispatch(actions.catchError({ error, callType: callTypes.action }))
    })
}
export const fetchSettings = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return requestFromServer
    .fetchSettings()
    .then((response) => {
      const setting = response.data
      dispatch(actions.customerFetched({ customerForEdit: setting }))
    })
    .catch((errorRes) => {
      let error = errorRes
      dispatch(actions.catchError({ error }))
    })
}

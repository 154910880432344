import { createSlice } from '@reduxjs/toolkit'

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  lastError: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // getCustomerById
    customerFetched: (state, action) => {
      state.list = false
      state.actionsLoading = false
      state.listLoading = false
      state.customerForEdit = action.payload.customerForEdit
      state.error = null
    },

    // createCustomer
    customerCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
    },
  },
})

import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

const getFieldCSSClasses = (touched, errors, isAvoidValidation) => {
  const classes = ['form-control']

  if (isAvoidValidation === undefined || isAvoidValidation === false) {
    if (touched && errors) {
      classes.push('is-invalid')
    }

    if (touched && !errors) {
      classes.push('is-valid')
    }
  }

  return classes.join(' ')
}

export function DatePickerField({
  label,
  extraLabel = null,
  isRequired = false,
  ...props
}) {
  const { setFieldValue, handleSubmit, errors, touched } = useFormikContext()
  const [field] = useField(props)

  return (
    <>
      {label && (
        <label>
          {label}
          {extraLabel ? (
            <label style={{ fontSize: '10px' }}>{extraLabel}</label>
          ) : null}
          {isRequired ? <label className="text-danger mb-0">*</label> : null}
        </label>
      )}
      <div className="input-group react-datepicker__input-container">
        {
          <DatePicker
            className={getFieldCSSClasses(
              touched[field.name],
              errors[field.name],
              props.isAvoidValidation,
            )}
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => {
              setFieldValue(field.name, val)
              props.isHandleSubmit && handleSubmit()
            }}
          />
        }
      </div>

      {/* {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        (props.isNoteHide === undefined || props.isNoteHide === false) && (
          <div className="feedback">
            Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
          </div>
        )
      )} */}
    </>
  )
}

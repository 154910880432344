import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { VideoTestimonialList } from './tyPageTestimonial/TYPageTestimonialList'

import { LayoutSplashScreen, ContentRoute } from '../../../../_metronic/layout'

export default function VideoTestimonials() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute
          path="/thank-you-page-video"
          component={VideoTestimonialList}
        />
      </Switch>
    </Suspense>
  )
}

import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { BuilderPage } from './pages/BuilderPage'
import { MyPage } from './pages/MyPage'
import { ChangePassword } from './pages/ChangePassword'
import { DashboardPage } from './pages/DashboardPage'
import Handler401 from '../redux/handler401'
import VideoTestimonials from './modules/VideoTestimonials/pages/VideoTestimonial'
import TYPageTestimonial from './modules/ThankYouPageVideo/pages/TYPageTestimonial'

const CourseCategoryPage = lazy(() =>
  import('./modules/CourseCategory/pages/CourseCategoryPage'),
)

const CoursePage = lazy(() => import('./modules/Course/pages/CoursePage'))
const ToolsCategoty = lazy(() =>
  import('./modules/ToolsModule/pages/ToolsCategoty'),
)
const InstructorPage = lazy(() =>
  import('./modules/Instructors/pages/InstructorPage'),
)

const StudentPage = lazy(() => import('./modules/Students/pages/StudentPage'))

const Offers = lazy(() => import('./modules/Offers/pages/OfferPage'))

const AdminUserPage = lazy(() =>
  import('./modules/AdminUsers/pages/AdminUserPage'),
)

const FormLeadsPage = lazy(() =>
  import('./modules/FormLeads/pages/FormLeadPage'),
)

const ActivityLogsPage = lazy(() =>
  import('./modules/ActivityLogs/pages/ActivityPage'),
)

const PaymentMethodPage = lazy(() =>
  import('./modules/PaymentMethod/pages/PaymentMethod'),
)
const OrderPage = lazy(() => import('./modules/Orders/pages/OrderPage'))

const AnnouncementPage = lazy(() =>
  import('./modules/Announcement/pages/AnnouncementPage'),
)

const TestimonialPage = lazy(() =>
  import('./modules/Testimonials/pages/TestimonialPage'),
)

const Settings = lazy(() => import('./modules/Settings/Settings'))

const FAQ = lazy(() => import('./modules/FAQ/faqpage'))

const KeySkills = lazy(() => import('./modules/KeySkill/pages/KeySkillPage'))

const Pages = lazy(() => import('./modules/Page/pages/PagesPage'))

const Tags = lazy(() => import('./modules/Tags/pages/TagPage'))

const JobRolePage = lazy(() => import('./modules/JobRoles/pages/JobRolePage'))

export default function BasePage() {
  const { user } = useSelector((state) => state.auth)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/change-password" component={ChangePassword} />
        <Route path="/tools" component={ToolsCategoty} />
        <Route path="/category" component={CourseCategoryPage} />
        <Route path="/course" component={CoursePage} />
        <Route path="/instructors" component={InstructorPage} />
        <Route path="/offers" component={Offers} />
        {user && user.roleID !== 2 ? (
          <Route path="/admin-users" component={AdminUserPage} />
        ) : null}
        <Route path="/form-leads" component={FormLeadsPage} />
        <Route path="/activity-logs" component={ActivityLogsPage} />
        <Route path="/payment-method" component={PaymentMethodPage} />
        <Route path="/orders" component={OrderPage} />
        <Route path="/401" component={Handler401} />
        <Route path="/students" component={StudentPage} />
        <Route path="/announcements" component={AnnouncementPage} />
        <Route path="/testimonials" component={TestimonialPage} />
        <Route path="/settings" component={Settings} />
        <Route path="/faq" component={FAQ} />
        <Route path="/skill" component={KeySkills} />
        <Route path="/page" component={Pages} />
        <Route path="/tags" component={Tags} />
        <Route path="/job-roles" component={JobRolePage} />
        <Route path="/video-testimonials" component={VideoTestimonials} />
        <Route path="/thank-you-page-video" component={TYPageTestimonial} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}

import axios from 'axios'
import store from './store'
import { API_URL, PROXY_URL } from '../config'

const baseURL = API_URL
export const proxyURL = PROXY_URL

export const Api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const Urls = {
  baseURL,
  login: '/secure/login',
  register: '/secure/register',
  changePassword: '/secure/changePassword',
  forgotPassword: '/auth/forgotPassword',
  resetPassword: '/secure/resetPassword',
  createInstructor: '/instructor',
  getAllInstructor: '/instructor',
  getInstructorById: '/instructor',
  updateCustomerById: '/instructor',
  deleteCustomerById: '/instructor',
  deleteAllInstructor: '/instructor',
  getAllAdminUser: '/adminList',
  createAdminUser: '/secure/register_manual',
  deleteAdminById: '/admin',
  getAdminById: '/admin',
  updateAdminById: '/admin',
  updateStatusOfAdminById: '/admin',
  deleteAllAdminById: '/admin',
  orders: '/order',
  courses: '/course',
  sortorder: '/course/sortorder',
  frCourseList: '/frCourseList',
  learningmode: '/learningmode',
  updateAllInstructorStatusById: '/instructor',
  formLeads: '/listForm',
  formLeadUpdate: '/lead',
  courseCategory: '/course/category',
  lmsCourses: 'lms/products',
  tools: '/tools',
  toolsAll: '/toolsAll',
  whoShouldDo: '/whoshoulddo',
  courseHeaders: '/courseSidebar',
  jobRole: '/JobRoles',
  keyskill: '/keyAllSkills',
  keykills: '/keySkills',
  pages: '/pages',
  pageById: '/pageById',
  tags: '/Tags',
  city: '/city',
  getAllTools: '/tools',
  createTools: '/tools',
  deleteToolbyId: '/tools',
  updateStatusOfTools: '/tools',
  getToolById: '/tools',
  deleteAllTools: '/tools',
  updateTools: '/tools',
  categoryList: '/course/category',
  categoryNameCheck: '/course/categoryName',
  announcements: '/announcement',
  testimonials: '/testimonial',
  AddTestimonials: '/testimonials',
  students: '/student',
  studentsCSV: '/csv/student',
  ordersCSV: '/csv/order',
  leadsCSV: '/csv/lead',
  ActivitiLogsCSV: '/csv/activityLog',
  studentAll: '/studentAll',
  upcomingBatches: '/upcomingBatches',
  deleteBatch: '/upcomingBatch',
  upcomingBatchesListByCourseId: '/upcomingBatchesListByCourseId',
  activityLog: '/activityLog',
  paymentMethod: '/get-payment-method',
  updatePaymentMethod: '/update-payment-method',
  offers: '/offer',
  settings: '/setting',
  dashboard: 'dashboard',
  resetCache: '/clearCache',
  deleteCareerSupport: '/deleteCareerSupport',
  deleteCurriculumTab: '/deleteCurriculumTab',
  deleteCarriculumItem: '/deleteCurriculumItem',
  deleteJobRoleItem: '/deleteJobRoleItem',
  deleteProjectTitle: '/courseCapstoneProject',
  deleteAssignmentTitle: '/deleteAssignment',
  deleteCertificateTitle: '/deleteCertificationTitle',
  deleteCertificateImage: '/deleteCertificationImage',
  deleteFAQItem: '/delete_faqs',
  updateFAQItem: '/create_update_faqs',
  fetchFAQItem: '/get_faqs',
  cloneCourse: '/cloneCourse',
  carriculumSortOrder: '/curriculumDetails',
  fetchFAQCourse: '/get_course_faq',
  updateFAQCourse: '/update_course_faq',
  deleteFAQCourse: '/deleteFAQ',
  parentCourseList: '/get_parent_course_list',
}

Api.interceptors.request.use((config) => {
  const {
    auth: { authToken },
  } = store.getState()
  if (authToken) {
    config.headers.Authorization = `bearerAuth ${authToken}`
  }
  return config
})

Api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401 && error.config.headers.Authorization) {
      window.location.replace('/401')
    }
    return Promise.reject(error)
  },
)

import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { VideoTestimonialList } from './videoTestimonial/VideoTestimonialList'

import { LayoutSplashScreen, ContentRoute } from '../../../../_metronic/layout'

export default function VideoTestimonials() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/video-testimonials"
            to="/video-testimonials/list"
          />
        }
        <ContentRoute
          path="/video-testimonials/list"
          component={VideoTestimonialList}
        />
      </Switch>
    </Suspense>
  )
}

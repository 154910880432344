// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect } from 'react'
import { Modal, Card } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Input } from '../../_metronic/_partials/controls'
import * as Yup from 'yup'
import { useSubheader } from '../../_metronic/layout'
import * as actions from './_redux/pages/pagesActions'

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old Password is required')
    .min(5, 'Min 5 character required'),
  newPassword: Yup.string()
    .required('New Password is required')
    .min(5, 'Min 5 character required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .min(5, 'Min 5 character required')
    .test('passwords-match', "Passwords doesn't  match", function (value) {
      return this.parent.newPassword === value
    }),
})

export function ChangePassword({ history }) {
  const suhbeader = useSubheader()
  suhbeader.setTitle('Change Password')

  const dispatch = useDispatch()
  const { actionsLoading, changePasswordRes } = useSelector(
    (state) => ({
      actionsLoading: state.pages.actionsLoading,
      changePasswordRes: state.pages,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (
      changePasswordRes.response !== undefined &&
      changePasswordRes.response.status === 200
    ) {
      history.push('/')
    }
  }, [history, changePasswordRes])

  const changePassword = (values) => {
    dispatch(actions.changePassword(values))
  }

  const onHide = () => {
    history.push('/')
  }

  return (
    <>
      <Card style={{ flex: 1 }}>
        <Card.Body>
          <Modal.Header className="pt-0 pl-0 mb-10">
            <Modal.Title id="example-modal-sizes-title-lg">
              Change Password
            </Modal.Title>
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            onSubmit={(values) => {
              changePassword(values)
            }}
            validationSchema={changePasswordSchema}
          >
            {({ handleSubmit, errors, touched }) => (
              <>
                <Modal.Body className="overlay pt-0">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right">
                    <div className="form-group row justify-content-end align-items-center">
                      <div className="col-lg-12">
                        <Field
                          type="password"
                          name="oldPassword"
                          component={Input}
                          placeholder="Old Password"
                          label="Old Password"
                          isRequired
                        />
                        <div
                          className="error"
                          style={{
                            color: '#f64e60',
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          <ErrorMessage name="oldPassword" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          type="password"
                          name="newPassword"
                          component={Input}
                          placeholder="New Password"
                          label="New Password"
                          isRequired
                        />
                        <div
                          className="error"
                          style={{
                            color: '#f64e60',
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          <ErrorMessage name="newPassword" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          type="password"
                          name="confirmPassword"
                          component={Input}
                          placeholder="Confirm password"
                          label="Confirm password"
                          isRequired
                        />
                        <div
                          className="error"
                          style={{
                            color: '#f64e60',
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }}
                        >
                          <ErrorMessage name="confirmPassword" />
                        </div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  )
}

import categoryTableMock from './categoryTableMock'
import MockUtils from './mock.utils'

export default function mockCategoryList(mock) {
  mock.onPost('api/customers').reply(({ data }) => {
    const { customer } = JSON.parse(data)
    const {
      duration = 0,
      firstName = '',
      lastName = '',
      email = '',
      userName = '',
      gender = 'Female',
      status = 0,
      dateOfBbirth = '01/01/2019',
      ipAddress = '127.0.0.1',
      courseFee_live = 0,
      courseFee_video = 0,
      courseFee_class = 0,
      learning_mode = '',
      type = 1,
    } = customer

    const id = generateUserId()
    const newCustomer = {
      id,
      duration,
      firstName,
      lastName,
      email,
      userName,
      gender,
      status,
      dateOfBbirth,
      ipAddress,
      courseFee_live,
      courseFee_video,
      courseFee_class,
      learning_mode,
      type,
    }
    categoryTableMock.push(newCustomer)
    return [200, { customer: newCustomer }]
  })

  mock.onPost('api/customers/find').reply((config) => {
    const mockUtils = new MockUtils()
    const { queryParams } = JSON.parse(config.data)
    const filterdCustomers = mockUtils.baseFilter(
      categoryTableMock,
      queryParams,
    )
    return [200, filterdCustomers]
  })

  mock.onPost('api/customers/deleteCustomers').reply((config) => {
    const { ids } = JSON.parse(config.data)
    ids.forEach((id) => {
      const index = categoryTableMock.findIndex((el) => el.id === id)
      if (index > -1) {
        categoryTableMock.splice(index, 1)
      }
    })
    return [200]
  })

  mock.onPost('api/customers/updateStatusForCustomers').reply((config) => {
    const { ids, status } = JSON.parse(config.data)
    categoryTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status
      }
    })
    return [200]
  })

  mock.onGet(/api\/customers\/\d+/).reply((config) => {
    const id = config.url.match(/api\/customers\/(\d+)/)[1]
    const customer = categoryTableMock.find((el) => el.id === +id)
    if (!customer) {
      return [400]
    }

    return [200, customer]
  })

  mock.onPut(/api\/customers\/\d+/).reply((config) => {
    const id = config.url.match(/api\/customers\/(\d+)/)[1]
    const { customer } = JSON.parse(config.data)
    const index = categoryTableMock.findIndex((el) => el.id === +id)
    if (!index) {
      return [400]
    }

    categoryTableMock[index] = { ...customer }
    return [200]
  })

  mock.onDelete(/api\/customers\/\d+/).reply((config) => {
    const id = config.url.match(/api\/customers\/(\d+)/)[1]
    const index = categoryTableMock.findIndex((el) => el.id === +id)
    categoryTableMock.splice(index, 1)
    if (!index === -1) {
      return [400]
    }

    return [200]
  })
}

function generateUserId() {
  const ids = categoryTableMock.map((el) => el.id)
  const maxId = Math.max(...ids)
  return maxId + 1
}

/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from '../app/Routes'
import { I18nProvider } from '../_metronic/i18n'
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout'
import { API_URL } from '../config'
//sentry
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'
Sentry.init({
  dsn:
    'https://2b51ba13ef214997aa60c2368eba8532@o927437.ingest.sentry.io/5893918',
  integrations: [new Integrations.BrowserTracing()],
  environment:
    API_URL === 'https://dev.analytixlabs.co.in/api'
      ? 'Staging'
      : API_URL === 'https://www.analytixlabs.co.in/api'
      ? 'production'
      : 'Other',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

function Testing() {
  let user = useSelector((state) => state.auth.user)
  if (user) {
    Sentry.configureScope((scope) => scope.setUser(user))
  }
  return <div></div>
}
export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <Testing />
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
              <GoogleReCaptchaProvider reCaptchaKey="6LfGr_UUAAAAAHGdMOGILBzFu0wlqF-WQd6I5g40">
                {/* Render routes with provided `Layout`. */}
                <Routes />
                </GoogleReCaptchaProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  )
}

import { Urls } from '../../redux/axiosSetup'
export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
  ele.className = ele.className.replace(reg, ' ')
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls)
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname

export const getFullURL = (value) => {
  return value.includes('http' || 'https') ? value : Urls.baseURL + '/' + value
}
export const getFullAddonURL = (addonURL, value) => {
  return value.includes('http' || 'https') ? value : addonURL + value
}
export const getPostAddonURL = (addonURL, value) => {
  return value.includes(addonURL) ? value.replace(addonURL, '') : value
}

export const validateImageType = (
  imgObj,
  setStatus,
  status,
  imgAllowType = [],
  inputFieldName = 'image',
  imgAllowSizeMsg = '',
) => {
  if (imgAllowType.length > 0) {
    if (!imgAllowType.includes(imgObj.type)) {
      setStatus({
        ...status,
        [inputFieldName]:
          imgAllowSizeMsg !== ''
            ? imgAllowSizeMsg
            : 'Only allow JPG,PNG,JPEG,GIF,WEBP',
      })
    } else {
      setStatus({
        ...status,
        [inputFieldName]: '',
      })
    }
  }
}

export const validateImageSize = (
  imgObj,
  setStatus,
  status,
  imgAllowSize = {},
  inputFieldName = 'image',
  imgAllowSizeMsg = '',
) => {
  if (Object.keys(imgAllowSize).length > 0) {
    let img = new Image()
    img.onload = function () {
      if (
        imgAllowSize.width !== this.width ||
        imgAllowSize.height !== this.height
      ) {
        setStatus({
          ...status,
          [inputFieldName]:
            imgAllowSizeMsg !== ''
              ? imgAllowSizeMsg
              : 'Upload valid size: ' +
                imgAllowSize.width +
                '*' +
                imgAllowSize.height,
        })
      } else {
        setStatus({
          ...status,
          [inputFieldName]: '',
        })
      }
    }
    img.onerror = function () {
      alert('not a valid file: ' + imgObj.type)
    }
    img.src = (window.URL || window.webkitURL).createObjectURL(imgObj)
  }
}

import { createSlice } from '@reduxjs/toolkit'

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  response: {},
  lastError: null,
  dashboardData: {},
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
      state.response = action.payload
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
      state.response = action.payload
    },

    changePasswordSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.response = action.payload
    },

    getDashboardDetailsSuccess: (state, action) => {
      state.actionsLoading = false
      state.error = null
      state.dashboardData = action.payload
    },

    getDashboardDetailsFailed: (state, action) => {
      state.dashboarError = `${action.payload.error}`
      state.actionsLoading = false
    },
  },
})

import React, { createContext, useContext, useState, useCallback } from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter } from './ActivitiesUIHelpers'

const CustomersUIContext = createContext()

export function useCustomersUIContext() {
  return useContext(CustomersUIContext)
}

export const CustomersUIConsumer = CustomersUIContext.Consumer

export function CustomersUIProvider({ customersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter)
  const [ids, setIds] = useState([])
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const initCustomer = {
    id: undefined,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    city: '',
    confirmPassword: '',
    courseInterestedIn: '',
    image: '',
    designation: '',
    organization: '',
    linkedinUrl: '',
    mobile: '',
    // status: 0,
    // designation: "",
    //showOnWebsite: "",
  }

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCustomer,
    newCustomerButtonClick: customersUIEvents.newCustomerButtonClick,
    openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
    openDeleteCustomerDialog: customersUIEvents.openDeleteCustomerDialog,
    openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    openUpdateCustomersStatusDialog:
      customersUIEvents.openUpdateCustomersStatusDialog,
  }

  return (
    <CustomersUIContext.Provider value={value}>
      {children}
    </CustomersUIContext.Provider>
  )
}

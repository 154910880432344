import { createSlice } from '@reduxjs/toolkit'

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  lastError: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const studentsSlice = createSlice({
  name: 'students',
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    stopLoader: (state, action) => {
      state.listLoading = false
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // getCustomerById
    customerFetched: (state, action) => {
      state.actionsLoading = false
      state.customerForEdit = action.payload.customerForEdit
      state.error = null
    },
    // findCustomers
    customersFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },

    citiesFetched: (state, action) => {
      const { citiesArr } = action.payload
      state.listLoading = false
      state.error = null
      state.citiesArr = citiesArr
    },

    coursesFetched: (state, action) => {
      const { courseArr } = action.payload
      state.listLoading = false
      state.error = null
      state.courseArr = courseArr
    },

    // createCustomer
    customerCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
    },
    // updateCustomer
    customerUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
      state.customerForEdit = undefined
    },
    // deleteCustomer
    customerDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
    },
    // deleteCustomers
    customersDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
    },
    // customersUpdateState
    customersStatusUpdated: (state) => {
      state.actionsLoading = false
      state.error = null
    },
  },
})

import * as requestFromServer from './pagesApiCalls'
import { pagesSlice, callTypes } from './pagesSlice'
import { NotificationManager } from 'react-notifications'

const { actions } = pagesSlice

export const changePassword = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .changePassword(params)
    .then((response) => {
      let message =
        response.data !== undefined && response.data.message !== undefined
          ? response.data.message
          : ''
      if (response !== undefined && response.status === 200) {
        dispatch(actions.changePasswordSuccess(response))
        NotificationManager.success(message)
      } else {
        dispatch(actions.catchError({ response, callType: callTypes.action }))
        NotificationManager.error(message ? message : 'API called failed')
      }
    })
    .catch((error) => {
      error.clientMessage =
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.metadata !== undefined &&
        error.response.data.metadata.message !== ''
          ? error.response.data.metadata.message
          : "Something wen't wrong. Please try after sometimes"
      dispatch(actions.catchError({ error, callType: callTypes.action }))
      NotificationManager.error(error.clientMessage)
    })
}

export const getDashboardDetails = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return requestFromServer
    .getDashboardDetails()
    .then((response) => {
      const { data: dashboardData = {} } = response
      dispatch(actions.getDashboardDetailsSuccess(dashboardData))
    })
    .catch((errorRes) => {
      let error = errorRes.response
      // error.clientMessage = error.response !== undefined && error.response.data !== undefined && error.response.data.metadata !== undefined && error.response.data.metadata.message !== '' ? error.response.data.metadata.message : "Something wen't wrong. Please try after sometimes";
      dispatch(actions.getDashboardDetailsFailed({ error }))
      // NotificationManager.error(error.clientMessage);
    })
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Dashboard } from '../../_metronic/_partials'
import * as actions from './_redux/pages/pagesActions'

export function DashboardPage() {
  // Dashboard Redux state
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.getDashboardDetails())
  }, [dispatch])

  return <Dashboard />
}

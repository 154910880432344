/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'

export function AsideMenuDetail({ layoutProps }) {
  const { user } = useSelector((state) => state.auth)
  const location = useLocation()
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? ' menu-item-active menu-item-open '
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/**Category Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/category',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/category">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')} />
            </span>
            <span className="menu-text">Category</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/category/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/category/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all categories</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/category/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/category/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new category</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Category ends */}

        {/* Course */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/course',
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/course">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Devices/Display2.svg')}
              />
            </span>
            <span className="menu-text">Course</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">View all courses</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive('/course/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/course/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all courses</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/course/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/course/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new course</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/** Tools */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/tools',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/tools">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Tools/Tools.svg')} />
            </span>
            <span className="menu-text">Tool</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/tools/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/tools/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all tools</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/tools/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/tools/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new tool</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Tools End */}
        {/** KeySkill menu Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/skill',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/skill">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')} />
            </span>
            <span className="menu-text">Key Skill</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/skill/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/skill/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all skills</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/skill/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/skill/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new skill</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** KeySkill menu End */}
        {/** Tags menu Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/tags',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/tags">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')} />
            </span>
            <span className="menu-text">Tags</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/tags/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/tags/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all tags</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/tags/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/tags/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new tag</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Tags menu End */}
        {/** Job roles Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/job-roles',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/job-roles">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Devices/Display2.svg')}
              />
            </span>
            <span className="menu-text">Job Roles</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/job-roles/list',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/job-roles/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all job role</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/job-roles/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/job-roles/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new job role</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Job roles menu End */}
        {/** Offer menu Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/offers',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/offers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Sale2.svg')} />
            </span>
            <span className="menu-text">Offer</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/offers/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/offers/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all offers</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/offers/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/offers/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new offer</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*Offer menu End*/}
        {/** Instructor Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/instructors',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/instructors">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Code/Info-circle.svg')}
              />
            </span>
            <span className="menu-text">Instructor</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/instructors/list',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/instructors/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all instructors</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/instructors/add',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/instructors/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new instructor</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Instructor End */}
        {/** Admin Users */}
        {user && user.roleID !== 2 ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              '/admin-users',
            )}`}
            aria-haspopup="false"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin-users">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Shield-user.svg',
                  )}
                />
              </span>
              <span className="menu-text">Admin User</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin-users/list',
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin-users/list">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">View all admin users</span>
                    </NavLink>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/admin-users/add',
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/admin-users/add">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Add new admin user</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </ul>
            </div>
          </li>
        ) : null}
        {/** Admin End */}

        {/**Student Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/students',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/students">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/Shield-user.svg',
                )}
              />
            </span>
            <span className="menu-text">Student</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/students/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/students/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all students</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/students/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/students/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new student</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Student ends */}
        {/** Orders Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/orders/list',
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')} />
            </span>
            <span className="menu-text">Order</span>
          </NavLink>
        </li>
        {/** Orders End */}
        {/** Form Lead Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/form-leads/list',
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/form-leads">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
              />
            </span>
            <span className="menu-text">Form Lead</span>
          </NavLink>
        </li>
        {/** Form Lead End */}

        {/** Activity Logs Start */}
        <li
          className={`menu-item ${getMenuItemActive('/activity-logs/list')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/activity-logs">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
              />
            </span>
            <span className="menu-text">Activity Log</span>
          </NavLink>
        </li>
        {/** Activity Logs End */}
        {/** Payment Method Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/payment-method',
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/payment-method">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/Shield-user.svg',
                )}
              />
            </span>
            <span className="menu-text">Payment Method</span>
          </NavLink>
        </li>
        {/** Payment Method End */}
        {/** Announcement Users */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/announcements',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/announcements">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/Shield-user.svg',
                )}
              />
            </span>
            <span className="menu-text">Announcement</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/announcements/list',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/announcements/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all announcements</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/announcements/add',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/announcements/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new announcement</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Announcement End */}
        {/** Testimonials Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/testimonials',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/testimonials">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Files/Selected-file.svg')}
              />
            </span>
            <span className="menu-text">Testimonial</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/testimonials/list',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/testimonials/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all testimonials</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/testimonials/add',
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/testimonials/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new testimonial</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        {/** Testimonials End */}
        {/** video Testimonials Start */}
        <li
          className={`menu-item ${getMenuItemActive(
            '/video-testimonials/list',
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/video-testimonials">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Files/Selected-file.svg')}
              />
            </span>
            <span className="menu-text">Video Testimonials</span>
          </NavLink>
        </li>
        {/** video testimonial End */}
        {/** Thank You Page Video Start */}
        <li
          className={`menu-item ${getMenuItemActive('/thank-you-page-video')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/thank-you-page-video">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Files/Selected-file.svg')}
              />
            </span>
            <span className="menu-text">Thank You Page Video</span>
          </NavLink>
        </li>
        {/** Thank You Page Video End */}
        {/** Pages menu Start */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/page',
          )}`}
          aria-haspopup="false"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/page">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')} />
            </span>
            <span className="menu-text">Pages</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive('/page/list')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/page/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View all Pages</span>
                  </NavLink>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive('/page/add')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/page/add">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add new page</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/** Pages menu End */}

        {/*FAQ: Start*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/faq')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/faq">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
              />
            </span>
            <span className="menu-text">FAQ</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/** FAQ End */}
        {/*Settings: Start*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/settings')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')}
              />
            </span>
            <span className="menu-text">Settings</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/** Settings End */}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}

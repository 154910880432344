import React from 'react'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'

const getFieldCSSClasses = (touched, errors, status) => {
  const classes = ['form-control']
  if ((touched && errors) || status) {
    classes.push('is-invalid')
  }

  if (touched && !errors && !status) {
    classes.push('is-valid')
  }

  return classes.join(' ')
}

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, status }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = 'text',
  extraLabel = null,
  isAddon = false,
  isAddonClass = null,
  isRequired = false,
  ...props
}) {
  return (
    <>
      {label && (
        <label>
          {label}
          {extraLabel ? (
            <label style={{ fontSize: '10px', margin: 0, padding: 0 }}>
              {extraLabel}
            </label>
          ) : null}
          {isRequired ? <label className="text-danger  mb-0">*</label> : null}
        </label>
      )}
      <div className="input-group">
        {isAddon ? (
          <span
            className={`form-control input-group-addon col-md-2 ${isAddonClass}`}
          >
            {isAddon}
          </span>
        ) : null}
        <input
          type={type}
          className={getFieldCSSClasses(
            touched[field.name],
            errors[field.name],
            status !== undefined ? status[field.name] : '',
          )}
          {...field}
          {...props}
        />
      </div>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}

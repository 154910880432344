/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_helpers'
import { Dropdown } from 'react-bootstrap'
import { DropdownMenuCustom } from '../../dropdowns'
import moment from 'moment'

export function CustomTablesWidget({ className, offers }) {
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Offers Expired Soon
          </span>
        </h3>

        <div className="card-toolbar">
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              id="dropdown-toggle-top2"
              variant="transparent"
              className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"
            >
              Sort By
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenuCustom />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th className="p-0" style={{ width: '50px' }} />
                <th className="p-0" style={{ minWidth: '200px' }} />
                <th className="p-0" style={{ minWidth: '100px' }} />
                <th className="p-0" style={{ minWidth: '125px' }} />
                <th className="p-0" style={{ minWidth: '110px' }} />
              </tr>
            </thead>
            <tbody>
              {offers.count > 0 &&
                offers.rows.length > 0 &&
                offers.rows.map((offer) => {
                  let offerStartDate = moment(offer.discountStartDate).format(
                    'MM-DD-YYYY HH:mm:ss',
                  )
                  let offerEndDate = moment(offer.discountEndDate).format(
                    'MM-DD-YYYY HH:mm:ss',
                  )
                  let remainingDays = moment(offerEndDate).diff(
                    moment(offerStartDate),
                    'days',
                  )

                  return (
                    <tr key={offer.offerID}>
                      <td className="pl-0 py-4">
                        <div className="symbol symbol-50 symbol-light-primary mr-5">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-danger">
                              <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Home/Clock.svg',
                                )}
                              ></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="pl-0">
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {offer.offerName}
                        </a>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {' '}
                          {offerStartDate}{' '}
                        </span>
                        <span className="text-muted font-weight-bold">
                          Start Date
                        </span>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {offerEndDate}
                        </span>
                        <span className="text-muted font-weight-bold">
                          End Date
                        </span>
                      </td>
                      <td className="text-right">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {remainingDays}
                        </span>
                        <span className="text-muted font-weight-bold">
                          Days Remaining
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

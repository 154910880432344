import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
} from '../../../../../_metronic/_partials/controls'
import { VideoTestimonialAdd } from './videoTestimonial-add/videoTestimonialAdd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../Settings/_redux/settings/settingsActions'

export function VideoTestimonialCard() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { customerForEdit, error } = useSelector(
    (state) => ({
      customerForEdit: state.settings.customerForEdit,
      error: state.settings.error,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(actions.fetchSettings())
  }, [dispatch])

  const updateSettings = (customer) => {
    setLoading(true)
    dispatch(actions.updateSettings(customer)).then(() => {
      setLoading(false)
      dispatch(actions.fetchSettings())
    })
  }

  return (
    <Card>
      <CardHeader title="Video Testimonial"></CardHeader>
      <CardBody>
        {error ? (
          <div
            role="alert"
            className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
          >
            <div className="alert-text font-weight-bold">
              {error?.data?.message ||
                error?.data?.metadata?.message ||
                error.statusText ||
                'Something went wrong. Please try again '}
            </div>
          </div>
        ) : null}
        <VideoTestimonialAdd
          updateSettings={updateSettings}
          actionsLoading={loading}
          customer={customerForEdit}
        />
      </CardBody>
    </Card>
  )
}

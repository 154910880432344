import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { DonutMixedWidget, CustomTablesWidget } from '../widgets'
import colors from '../../../app/helpers/utils/colors'
export function SubDashboard() {
  const { dashboardData } = useSelector(
    (state) => ({
      dashboardData: state.pages.dashboardData,
      // actionsLoading: state.pages.actionsLoading,
    }),
    shallowEqual,
  )
  const {
    superAdmin = {},
    admin = {},
    cityDetails = {},
    courseDetails = {},
    learningTacksDetails = {},
    offers = {},
    studentDetails = {},
  } = dashboardData

  let totalSACount = 0,
    activeSA = 0,
    inactiveSA = 0,
    deletedSA = 0
  if (superAdmin && Object.keys(superAdmin).length > 0) {
    totalSACount = superAdmin.superAdminCount ? superAdmin.superAdminCount : 0
    activeSA = superAdmin.activeSuperAdmin ? superAdmin.activeSuperAdmin : 0
    deletedSA = superAdmin.deleteSuperAdmin ? superAdmin.deleteSuperAdmin : 0
  }

  let totalAdmin = 0,
    activeAdmin = 0,
    inactiveAdmin = 0,
    deletedAdmin = 0
  if (admin && Object.keys(admin).length > 0) {
    totalAdmin = admin.adminCount !== null ? admin.adminCount : 0
    activeAdmin = admin.activeAdmin ? admin.activeAdmin : 0
    deletedAdmin = admin.deleteAdmin ? admin.deleteAdmin : 0
  }

  let totalCities = 0,
    activeCities = 0,
    inactiveCities = 0,
    deletedCities = 0
  if (cityDetails && Object.keys(cityDetails).length > 0) {
    totalCities = cityDetails.total ? cityDetails.total : 0
    activeCities = cityDetails.active ? cityDetails.active : 0
    inactiveCities = cityDetails.inActive ? cityDetails.inActive : 0
    deletedCities = cityDetails.deleted ? cityDetails.deleted : 0
  }

  let totalCourses = 0,
    activeCourses = 0,
    inactiveCourses = 0,
    deletedCourses = 0
  if (courseDetails && Object.keys(courseDetails).length > 0) {
    totalCourses = courseDetails.total ? courseDetails.total : 0
    activeCourses = courseDetails.active ? courseDetails.active : 0
    inactiveCourses = courseDetails.inActive ? courseDetails.inActive : 0
    deletedCourses = courseDetails.deleted ? courseDetails.deleted : 0
  }

  let totalLearningTacks = 0,
    activeLearningTacks = 0,
    inactiveLearningTacks = 0,
    deletedLearningTacks = 0
  if (learningTacksDetails && Object.keys(learningTacksDetails).length > 0) {
    totalLearningTacks = learningTacksDetails.total
      ? learningTacksDetails.total
      : 0
    activeLearningTacks = learningTacksDetails.active
      ? learningTacksDetails.active
      : 0
    inactiveLearningTacks = learningTacksDetails.inActive
      ? learningTacksDetails.inActive
      : 0
    deletedLearningTacks = learningTacksDetails.deleted
      ? learningTacksDetails.deleted
      : 0
  }

  let totalStudents = 0,
    activeStudents = 0,
    inactiveStudents = 0,
    deletedStudents = 0
  if (studentDetails && Object.keys(studentDetails).length > 0) {
    totalStudents = studentDetails.total ? studentDetails.total : 0
    activeStudents = studentDetails.active ? studentDetails.active : 0
    inactiveStudents = studentDetails.inActive ? studentDetails.inActive : 0
    deletedStudents = studentDetails.deleted ? studentDetails.deleted : 0
  }
  return (
    <>
      <div className="row">
        {courseDetails && Object.keys(courseDetails).length > 0 && (
          <div className="col-lg-4">
            <DonutMixedWidget
              className="gutter-b"
              item={{
                title: 'Total Course',
                discription: 'Total number of courses created.',
                count: totalCourses,
                id: 'kt_mixed_widget_0_chart',
                series: [activeCourses, inactiveCourses, deletedCourses],
                labels: ['Active', 'Inactive', 'Deleted'],
                colors: [
                  colors.caribbeanGreen,
                  colors.darkOrange,
                  colors.radicalRed,
                ],
              }}
            />
          </div>
        )}
        {learningTacksDetails && Object.keys(learningTacksDetails).length > 0 && (
          <div className="col-lg-4">
            <DonutMixedWidget
              className="gutter-b"
              item={{
                title: 'Learning Tracks',
                discription: 'Total number of learning tracks created.',
                count: totalLearningTacks,
                id: 'kt_mixed_widget_2_chart',
                series: [
                  activeLearningTacks,
                  inactiveLearningTacks,
                  deletedLearningTacks,
                ],
                labels: ['Active', 'Inactive', 'Deleted'],
                colors: [
                  colors.caribbeanGreen,
                  colors.darkOrange,
                  colors.radicalRed,
                ],
              }}
            />
          </div>
        )}
        {studentDetails && Object.keys(studentDetails).length > 0 && (
          <div className="col-lg-4">
            <DonutMixedWidget
              className="gutter-b"
              item={{
                title: 'Students',
                discription: 'Total number of students registered.',
                count: totalStudents,
                id: 'kt_mixed_widget_3_chart',
                series: [activeStudents, inactiveStudents, deletedStudents],
                labels: ['Active', 'Inactive', 'Deleted'],
                colors: [
                  colors.caribbeanGreen,
                  colors.darkOrange,
                  colors.radicalRed,
                ],
              }}
            />
          </div>
        )}
        {admin && Object.keys(admin).length > 0 && (
          <div className="col-lg-4">
            <DonutMixedWidget
              className="gutter-b"
              item={{
                title: 'Admin User',
                discription: 'Total number of admin users created.',
                count: totalAdmin,
                id: 'kt_mixed_widget_4_chart',
                series: [activeAdmin, inactiveAdmin, deletedAdmin],
                labels: ['Active', 'Inactive', 'Deleted'],
                colors: [
                  colors.caribbeanGreen,
                  colors.darkOrange,
                  colors.radicalRed,
                ],
              }}
            />
          </div>
        )}
        {superAdmin && Object.keys(superAdmin).length > 0 && (
          <div className="col-lg-4">
            <DonutMixedWidget
              className="gutter-b"
              item={{
                title: 'Super Admin User',
                discription: 'Total number of super admin user created.',
                count: totalSACount,
                id: 'kt_mixed_widget_5_chart',
                series: [activeSA, inactiveSA, deletedSA],
                labels: ['Active', 'Inactive', 'Deleted'],
                colors: [
                  colors.caribbeanGreen,
                  colors.darkOrange,
                  colors.radicalRed,
                ],
              }}
            />
          </div>
        )}
        {cityDetails && Object.keys(cityDetails).length > 0 && (
          <div className="col-lg-4">
            <DonutMixedWidget
              className="gutter-b"
              item={{
                title: 'City',
                discription: 'Total number of city added.',
                count: totalCities,
                id: 'kt_mixed_widget_6_chart',
                series: [activeCities, inactiveCities, deletedCities],
                labels: ['Active', 'Inactive', 'Deleted'],
                colors: [
                  colors.caribbeanGreen,
                  colors.darkOrange,
                  colors.radicalRed,
                ],
              }}
            />
          </div>
        )}
        <div className="col-lg-12">
          <CustomTablesWidget
            className="card-stretch gutter-b"
            offers={offers}
          />
        </div>
      </div>
    </>
  )
}

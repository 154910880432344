import React from 'react'
import { VideoTestimonialLoadingDialog } from '../../../VideoTestimonials/pages/videoTestimonial/videoTestimonial-loading-dialog/VideoTestimonialLoadingDialog'
import { CustomersUIProvider } from '../../../VideoTestimonials/pages/videoTestimonial/CustomersUIContext'
import { TYPageTestimonialCard } from './TYPageTestimonialCard'

export function VideoTestimonialList({ history }) {
  const customersUIEvents = {}

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <VideoTestimonialLoadingDialog />
      <TYPageTestimonialCard />
    </CustomersUIProvider>
  )
}

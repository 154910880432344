import React from 'react'
import { VideoTestimonialLoadingDialog } from './videoTestimonial-loading-dialog/VideoTestimonialLoadingDialog'
import { CustomersUIProvider } from './CustomersUIContext'
import { VideoTestimonialCard } from './VideoTestimonialCard'

export function VideoTestimonialList({ history }) {
  const customersUIEvents = {}

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <VideoTestimonialLoadingDialog />
      <VideoTestimonialCard />
    </CustomersUIProvider>
  )
}

import axios from 'axios'
import { Api, Urls } from './../../../../redux/axiosSetup'

// export const ME_URL = "api/me";

export function login(email, password) {
  return Api.post(Urls.login, { email, password })
}

export function register(email, fullname, username, password) {
  return axios.post(Urls.register, { email, fullname, username, password })
}

export function resetPassword(password, verificationCode) {
  return Api.post(Urls.resetPassword, { password, verificationCode })
}

export function requestPassword(email) {
  return Api.post(Urls.forgotPassword, { email })
}

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   return instance.get(ME_URL);
// }

import { createSlice } from '@reduxjs/toolkit'

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  lastError: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const pageSlice = createSlice({
  name: 'pageSlice',
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // getCustomerById
    pageFetched: (state, action) => {
      state.actionsLoading = false
      state.customerForEdit = action.payload.customerForEdit
      state.error = null
    },
    // findCustomers
    pagesFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // createCustomer
    pageCreated: (state, action) => {
      state.actionsLoading = false
      state.error = null
    },
    // updateCustomer
    pageUpdated: (state, action) => {
      state.error = null
      state.actionsLoading = false
    },
    // Customer sort order update
    customerOrderUpdated: (state, action) => {
      state.error = null
      state.listLoading = false
    },
    // deleteCustomer
    customerDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
    },
    // deleteCustomers
    customersDeleted: (state, action) => {
      state.error = null
      state.actionsLoading = false
    },
  },
})

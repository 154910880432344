import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { Editor } from "@tinymce/tinymce-react";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getEditorCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function TextArea({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  extraLabel = null,
  isAddon = false,
  isRequired = false,
  iseditor = false,
  ...props
}) {

  return (
    <>
      {label && (
        <label>
          {label}
          {extraLabel ? (
            <label style={{ fontSize: "10px" }}>{extraLabel}</label>
          ) : null}
          {isRequired ? <label className="text-danger mb-0">*</label> : null}
        </label>
      )}
      {isAddon ? (
        <span className="form-control input-group-addon col-md-2">
          {isAddon}
        </span>
      ) : null}
      {iseditor ? (
        <div
          className={`input-group ${getEditorCSSClasses(
            touched[field.name],
            errors[field.name],
          )}`}
        >
          <Editor
             tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6/tinymce.min.js"
            textareaName={props.name}
            ref={props.refs}
            init={{
              setup: function (editor) {
                editor.on('init', function () {
                  editor.getContainer().className +=
                    ' form-control input-editor-addon'
                })
                editor.on('blur', (e) => {
                  if (props?.onBlurEvent) {
                    props.onBlurEvent(editor.getContent())
                  }
                })
              },
              height: 120,
              width: '100%',
              branding: false,
              menubar: false,
              statusbar: true,
              forced_root_block: false,
              force_br_newlines: true,
              force_p_newlines: false,
              convert_newlines_to_brs: true,
              indent: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic underline | backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | border | table preview | removeformat link ',
              external_plugins: {
                lists: 'https://cdn.jsdelivr.net/npm/tinymce@6/plugins/lists/plugin.min.js',
                advlist: 'https://cdn.jsdelivr.net/npm/tinymce@6/plugins/advlist/plugin.min.js',
                preview: 'https://cdn.jsdelivr.net/npm/tinymce@6/plugins/preview/plugin.min.js',
                table:'https://cdn.jsdelivr.net/npm/tinymce@6/plugins/table/plugin.min.js',
                link:'https://cdn.jsdelivr.net/npm/tinymce@6/plugins/link/plugin.min.js',
                format:'https://cdn.jsdelivr.net/npm/tinymce@6/plugins/format/plugin.min.js'
                },
            }}
            onEditorChange={props.onchange}
            {...field}
            {...props}
          />
        </div>
      ) : (
        <div className="input-group">
          <textarea
            type={type}
            className={getFieldCSSClasses(
              touched[field.name],
              errors[field.name],
            )}
            {...field}
            {...props}
          />
        </div>
      )}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
